<template>
  <div class="playlist-list">
    <b-row>
      <b-col cols="4" sm="3" lg="2" class="pl-sm-0">
        <player
          :player_type="'playlistasset'"
          :image="playlist.images"
          :file="playlist.file"
          :id="playlist.id"
          :title="playlist.name"
          :username="playlist.username"
          :queue="this.queue_songs"
        ></player>
      </b-col>

      <b-col cols="5" lg="6" class="pl-0">
        <div class="pl-0 playlist-info pl-lg-3 px-lg-3">
          <div class="text-light font-weight-light playlist-name-c">
            <router-link
              class="text-light playlist_name"
              :to="{ name: 'Playlist', params: { id: playlist.id } }"
            >
              {{ playlist.name }}
            </router-link>
            <!-- <router-link class="text-light font-weight-lighter artist-name" :to="{'name': 'SongProfile', 'params': {'username': song.username, 'id': song.id}}">{{ song.username }}</router-link> -->
          </div>
          <div class="created_by">
            <span class="text-light d-none d-md-inline">
              Created By {{ playlist.username }}</span
            >
          </div>
          <div class="text-light song-count">
            {{ playlist.song_count }}
            {{ playlist.song_count > 1 ? "songs" : "song" }}
            <span class="d-md-none d-sm-inline">
              | {{ playlist.total_songs_time_formatted }}</span
            >
          </div>
          <div v-if="this.featured">
            <img
              src="../../../assets/trackd-icon.png"
              style="width:16px; height:16px"
            />
          </div>
        </div>
      </b-col>

      <b-col cols="3" sm="4" class="c-playlist-actions">
        <span class="pl-2 btn playlist_time d-none d-md-block">
          {{ playlist.total_songs_time_formatted }}
        </span>
        <span class="pl-2 btn playlist_time d-none d-md-block">
          {{ playlist.likes }}
        </span>
        <span>
          <b-button
            v-if="playlist.liked"
            variant="link"
            @click="unlikePlaylist()"
            class=" pr-0"
          >
            <img
              src="../../../assets/heart-filled.svg"
              class="align-top text-white pr-0 song-heart-nav"
              height="30"
              alt="Menu"
            />
          </b-button>
          <b-button v-else variant="link" @click="likePlaylist()" class=" pr-0">
            <img
              src="../../../assets/heart.svg"
              class="align-top text-white pr-0 song-heart-nav"
              height="30"
              alt="Menu"
            />
          </b-button>
        </span>
        <span>
          <PlaylistActions v-bind:playlist="playlist"></PlaylistActions>
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PlaylistActions from "../../ancillary/library/playlist/actions.vue";
import player from "../../../components/player.vue";

export default {
  name: "PlaylistListAsset",
  props: ["playlist", "featured", "queue_songs"],
  components: {
    player,
    PlaylistActions,
  },
  methods: {
    ...mapActions("Playlist", ["like_playlist", "unlike_playlist"]),

    likePlaylist() {
      this.playlist.liked = true;
      this.playlist.likes += 1;
      this.like_playlist(this.playlist.id);
    },

    unlikePlaylist() {
      this.playlist.liked = false;
      this.playlist.likes -= 1;
      this.unlike_playlist(this.playlist.id);
    },
  },
};
</script>

<style scoped>
.container {
  padding-left: unset;
  padding-right: unset;
}

.c-playlist-actions {
  display: flex;
  justify-content: flex-end;
}

.playlist_name {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}
.created_by {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}

.song-count {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

.playlist_time {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: right;
  color: white;
}

.song-heart-nav {
  position: relative;
  top: -3px;
}

@media (max-width: 767px) {
  .playlist_name {
    font-size: 20px;
    line-height: 21px;
  }

  .playlist-list .song-heart-nav {
    width: 30px;
    height: 28px;
  }
  .song-heart-nav {
    height: 22px;
    width: 26px;
  }

  .song-count {
    font-size: 20px;
    line-height: 21px;
  }
}

@media (max-width: 575.98px) {
  .playlist_name {
    font-size: 16px;
    line-height: 21px;

    width: 29vw;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .song-count {
    font-size: 14px;
    line-height: 21px;
  }

  .song-heart-nav {
    position: relative;
    top: -1px;
    height: 25px;
  }

  .playlist-list .song-heart-nav {
    margin-right: -10px;
  }
}

@media (max-width: 375px) {
  .playlist_name {
    font-size: 16px;
    line-height: 21px;
  }
  .song-heart-nav {
    position: relative;
    top: -5px;
    height: 23px;
  }

  .playlist-name-c {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 125px !important;
  }

  .playlist-list .song-heart-nav {
    width: 23px;
    margin-right: -2px;
  }
}

@media (max-width: 320px) {
  .playlist_name {
    font-size: 16px;
    line-height: 21px;
  }

  .playlist-name-c {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 85px !important;
  }

  .song-heart-nav {
    position: relative;
    top: -3px;
    height: 20px;
  }
}
</style>
