<template>
  <div>
    <b-col cols="12" class="text-center text-light text-left mb-3 px-0">
      <b-row>
        <b-col
          cols="4"
          sm="3"
          md="3"
          lg="2"
          xl="2"
          class="pl-sm-0 c-image-container"
        >
          <div class="c-artist-image">
            <img
              v-if="artist.verified"
              src="../../../assets/verified.svg"
              class="artist-verified-list"
              alt="Verified Badge"
            />
            <b-img
              :src="
                getImagePath(artist.image, {
                  func: 'crop',
                  width: 150,
                  height: 150,
                })
              "
              blank-color="red"
              class="img-fluid photo-border text-center artist-image"
              alt="placeholder"
            ></b-img>

            <b-badge
              v-if="artist.supporter"
              pill
              variant="danger-outline"
              class="
              px-3 px-md-4
              py-1
              text-uppercase text-danger
              font-weight-bold
              border border-solid border-danger
              mr-1
              text-small
              border-danger-2
            "
              >Supporter</b-badge
            >
          </div>
        </b-col>

        <b-col cols="8" sm="9" md="9" lg="10" xl="10">
          <b-row class="pl-lg-3 pr-lg-0">
            <b-col class="text-left pl-0">
              <p class="text-light mt-0 pb-0 mb-2 artist-name">
                <img
                  v-if="artist.chipin_plus && artist.locked"
                  src="../../../assets/chipin+-locked-light.svg"
                  class="song-locked float-left mr-2 mr-md-3"
                  height="30"
                  alt="Logo"
                />
                <router-link
                  v-if="isAuthenticated"
                  class="text-light font-weight-normal artist-name"
                  v-html="elipsis(artist.name, artist_text_limit)"
                  :to="{ name: 'Artist', params: { id: artist.id } }"
                  >{{ artist.name }}</router-link
                >
                <a
                  v-else
                  class="text-light artist-name"
                  style="cursor: pointer"
                  v-html="elipsis(artist.name, 5)"
                  @click="$bvModal.show('login-modal')"
                  >{{ artist.name }}</a
                >
              </p>
              <p class="text-light mt-0 pb-0 mb-2 strap-line">
                {{ artist.strap_line }}
              </p>
              <img
                src="../../../assets/location-icon.svg"
                class="align-top mr-2"
                height="20"
                alt="Logo"
              />
              <span
                class="text-light mt-2 pb-0 mb-2 font-weight-light location"
                v-html="elipsis(artist.location, location_text_limit)"
              >
                {{ artist.location }}
              </span>
            </b-col>

            <b-col cols="2" sm="3" md="2" class="text-right pl-0 pr-0">
              <!-- Buttons -->

              <b-col cols="12" class="text-right ml-1">
                <ArtistActions
                  v-bind:artist="artist"
                  v-bind:index="index"
                  class="artist-actions"
                ></ArtistActions>
              </b-col>

              <b-col cols="12" class="text-right ml-1">
                <b-button
                  variant="outline-light"
                  v-if="artist.liked"
                  class="float-right follow-btn"
                  @click="unfollowArtist"
                  >Unfollow</b-button
                >
                <b-button
                  variant="outline-light"
                  v-else
                  class="float-right follow-btn"
                  @click="followArtist"
                  >Follow</b-button
                >
              </b-col>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <div class="wrapper">
              <b-button
                v-for="tag in artist.tags"
                :key="tag.id"
                class="genre-btn mt-3 mr-1 mr-sm-2"
              >
                {{ tag }}
              </b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <chipinplus
      v-if="artist.chipin_enabled && artist.chipin_plus"
      v-bind:user_id="artist.id"
      v-bind:username="artist.name"
      v-bind:index="index"
      v-bind:stripe_type="artist.made_on_stripe_account"
    ></chipinplus>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ArtistActions from "../../ancillary/library/artist/actions.vue";
import chipinplus from "../../../components/chipinplus-indexed.vue";

export default {
  name: "ArtistListAsset",
  props: ["artist"],
  components: {
    ArtistActions,
    chipinplus,
  },
  data: function() {
    return {
      index: null,
    };
  },
  methods: {
    ...mapActions("User", ["like_user", "unlike_user"]),
    followArtist() {
      this.artist.liked = true;
      this.like_user(this.artist.id);
    },
    unfollowArtist() {
      this.artist.liked = false;
      this.unlike_user(this.artist.id);
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),

    artist_text_limit() {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        return 100;
      } else if (window.matchMedia("(min-width: 992px)").matches) {
        return 50;
      } else if (window.matchMedia("(min-width: 768px)").matches) {
        return 45;
      } else if (window.matchMedia("(min-width: 576px)").matches) {
        return 30;
      } else if (window.matchMedia("(min-width: 375px)").matches) {
        return 22;
      } else {
        return 14;
      }
    },

    location_text_limit() {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        return 100;
      } else if (window.matchMedia("(min-width: 992px)").matches) {
        return 50;
      } else if (window.matchMedia("(min-width: 768px)").matches) {
        return 45;
      } else if (window.matchMedia("(min-width: 576px)").matches) {
        return 35;
      } else if (window.matchMedia("(min-width: 375px)").matches) {
        return 22;
      } else {
        return 15;
      }
    },
  },
  mounted() {
    this.index = Math.floor(Math.random() * 1000 + 1);
  },
};
</script>

<style scoped>
.c-artist-image {
  position: relative;
}
.artist-verified-list {
  position: absolute;
  right: -16px;
  top: 10px;
  z-index: 100;
  width: 30px;
}

.artist-name {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
}

.strap-line {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 34px;
}

.location {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
}

.follow-btn {
  width: 62px;
  height: 19px;
  line-height: 8px;
  font-size: 12px;
  padding: 0px;
  border: 1px solid #9b9b9b;
}

.genre-btn {
  background-color: transparent;
  border: 1px white solid;
  border-radius: 20px;
  width: 62px;
  height: 15px;
  line-height: 2px;
  font-size: 12px;
  font-weight: 400;
}

.artist-image {
  height: 80px;
  width: 80px;
}

.wrapper {
  min-width: 100%;
  height: 50px;
  /* border: 1px solid #ddd; */
  border-radius: 0;
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.c-image-container {
  display: flex;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

@media (min-width: 375px) {
  .artist-name {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
  }

  .strap-line {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 34px;
  }

  .location {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 23px;
  }

  .follow-btn {
    width: 62px;
    height: 19px;
    line-height: 8px;
    font-size: 12px;
    padding: 0px;
    border: 1px solid #9b9b9b;
  }

  .genre-btn {
    background-color: transparent;
    border: 1px white solid;
    border-radius: 20px;
    width: 62px;
    height: 15px;
    line-height: 2px;
    font-size: 12px;
    font-weight: 400;
  }

  .artist-image {
    height: 80px;
    width: 80px;
  }
}

@media (min-width: 576px) {
  .artist-name {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
  }

  .strap-line {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 34px;
  }

  .location {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 23px;
  }

  .follow-btn {
    width: 70px;
    height: 20px;
    line-height: 15px;
    font-size: 14px;
    border: 1px solid white;
  }

  .genre-btn {
    background-color: transparent;
    border: 2px white solid;
    border-radius: 20px;
    width: 80px;
    height: 25px;
    line-height: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .artist-image {
    height: 110px;
    width: 110px;
  }
  .song-locked {
    width: 25px;
    top: 3px;
    position: relative;
  }
}

@media (min-width: 768px) {
  .artist-name {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }

  .strap-line {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
  }

  .location {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
  }

  .follow-btn {
    width: 100px;
    height: 30px;
    line-height: 15px;
    font-size: 16px;
  }

  .genre-btn {
    background-color: transparent;
    border: 2px white solid;
    border-radius: 20px;
    width: 102px;
    height: 25px;
    line-height: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .artist-image {
    height: 150px;
    width: 150px;
  }

  .song-locked {
    width: 25px;
    top: 3px;
    position: relative;
  }
}

@media (min-width: 992px) {
  .artist-name {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }

  .strap-line {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
  }

  .location {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
  }

  .follow-btn {
    width: 100px;
    height: 30px;
    line-height: 15px;
  }

  .genre-btn {
    background-color: transparent;
    border: 2px white solid;
    border-radius: 20px;
    width: 102px;
    height: 25px;
    line-height: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width: 1200px) {
  .artist-name {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }

  .strap-line {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
  }

  .location {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
  }

  .follow-btn {
    width: 100px;
    height: 30px;
    line-height: 15px;
  }

  .genre-btn {
    background-color: transparent;
    border: 2px white solid;
    border-radius: 20px;
    width: 102px;
    height: 25px;
    line-height: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 992px) {
  .artist-verified-list {
    position: absolute;
    right: -10px;
    top: 5px;
    z-index: 100;
    width: 25px;
  }
}
@media (max-width: 786px) {
  .artist-verified-list {
    position: absolute;
    right: -10px;
    top: 5px;
    z-index: 100;
    width: 25px;
  }
  .song-locked {
    width: 22px;
    top: 3px;
    position: relative;
  }
}
@media (max-width: 414px) {
  .artist-verified-list {
    position: absolute;
    right: -10px;
    top: 5px;
    z-index: 100;
    width: 20px;
  }
  .song-locked {
    width: 20px;
    top: 3px;
    position: relative;
  }
}
@media (max-width: 320px) {
  .artist-verified-list {
    position: absolute;
    right: -8px;
    top: 3px;
    z-index: 100;
    width: 18px;
  }
  .song-locked {
    width: 18px;
    top: 3px;
    position: relative;
  }
}
</style>
